<template>
  <div
    class="list--item"
    :class="`${cuteList && 'cute-list'} ${status}`"
    @click="clickHandler"
    v-ripple
  >
    <div class="detail">
      <v-img
        center
        :src="pet.media[0]"
        class="image"
        height="50px"
        width="50px"
      ></v-img>

      <div class="total">
        <p class="name">
          {{ pet.name | trim(20) }}
          <v-icon v-if="pet.featured" size="12px" color="golden"
            >mdi-star</v-icon
          >
        </p>
        <p class="sm">
          <span class="kind">{{ pet.race && pet.race.name }}, </span>
          <span class="bold">{{ pet.age }}</span>
        </p>
      </div>
    </div>
    <span v-if="showStatus" class="status" :class="statusColor(status)">
      {{ status === "approved" ? "Accpeted" : status }}
    </span>
    <v-icon v-if="arrow" color="dark">mdi-arrow-right</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    pet: {
      type: Object,
      required: true,
    },
    hideStatus: {
      type: Boolean,
      default: false,
    },
    cuteList: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
  },
  computed: {
    showStatus() {
      const { cuteList, status } = this;
      if (cuteList) return false;
      return !!status;
    },
  },
  methods: {
    statusColor(status) {
      let color = "secondary";
      if (status === "rejected") color = "error--text bg--error";
      if (status === "review") color = "golden--text bg--golden";
      if (status === "approved") color = "white--text bg--success";
      return color;
    },
    clickHandler() {
      if (this.status === "approved") {
        this.$emit("pet-clicked", this.pet);
      } else {
        this.$router.push(`/pet-detail/${this.pet.id}`);
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}

p.sm {
  font-size: 12px;
  line-height: 22px;
  color: #010101;

  span.kind {
    opacity: 0.5;
  }

  span.bold {
    opacity: 1;
    font-weight: 500;
  }
}

.list {
  &--item {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // &.cute-list.accepted {
    //   opacity: 0.6;
    // }

    &:not(:last-child) {
      border-bottom: 0.5px solid #ddd;
    }

    .detail {
      display: flex;
      align-items: center;

      .image {
        border-radius: 10px;
      }

      .total {
        margin-left: 1rem;
        p.name {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
        }

        p.light {
          font-weight: normal;
          font-size: 10px;
          line-height: 16px;
          color: #000000;
          opacity: 0.5;
        }
      }
    }
  }
}

span.status {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-transform: capitalize;
  padding: 2px 10px;
  border-radius: 20px;

  &.bg-- {
    &secondary {
      background: #5defcc1a;
    }

    &error {
      background: #ff5f5f1a;
    }

    &golden {
      background: #f6c15a1a;
    }

    &success {
      background: #4eeab1d2;
    }
  }
}
</style>
