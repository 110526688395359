<template>
  <AppPage
    :title="$vuetify.lang.t('$vuetify.adoption_list')"
    container
    :loading="loading"
  >
    <h3>{{ $vuetify.lang.t("$vuetify.adoption_requests") }}</h3>
    <div class="list">
      <PetListView
        v-for="pet in adoptionList"
        :key="pet.id"
        :pet="pet.animal"
        :status="pet.status"
        @pet-clicked="petClicked"
      />

      <p class="subtitle-1 text-center" v-if="!loading && !adoptionList.length">
        {{ $vuetify.lang.t("$vuetify.no_list") }}
      </p>
    </div>

    <h3 class="mt-8">{{ $vuetify.lang.t("$vuetify.co_ownership") }}</h3>

    <div class="list">
      <PetListView
        v-for="pet in coOwnedList"
        :key="pet.id"
        :pet="pet.animal"
        :status="pet.status"
        @pet-clicked="petClicked"
      />
      <p class="subtitle-1 text-center" v-if="!loading && !coOwnedList.length">
        {{ $vuetify.lang.t("$vuetify.no_list") }}
      </p>
    </div>
  </AppPage>
</template>

<script>
import PetListView from "@/components/pet/pet-list-view";

import { createNamespacedHelpers } from "vuex";
const popupModule = createNamespacedHelpers("popup");
const petModule = createNamespacedHelpers("pet");

export default {
  name: "adoption-list",
  components: { PetListView },
  data() {
    return {};
  },

  computed: {
    ...petModule.mapState(["adoptionList", "coOwnedList", "loading"]),
  },
  methods: {
    ...popupModule.mapMutations(["SET_POPUP"]),
    ...petModule.mapActions(["FETCH_ADOPTION_REQUEST_LIST"]),
    petClicked(pet) {
      this.SET_POPUP({
        show: true,
        component: "pet-popup",
        modalOptions: {
          maxWidth: "90%",
        },
        componentProps: {
          pet,
        },
      });
    },
  },
  created() {
    this.FETCH_ADOPTION_REQUEST_LIST();
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
}
</style>
